@import "reset";
@import "bootstrap-grid";

@import "../node_modules/swiper/swiper.min.css";
$white: white;
$darkLiver: #515151;
$desertLand: #D1A987;
$flourescentBlue: #3CFFFF;
$black: #00172E;
$richBlack: #00172E;
$cultured: #F5F5F5;
$maastrictBlue: #00172d;


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;1,400&display=swap');

@mixin font-primario {
  font-family: 'Montserrat', sans-serif;
}

@mixin transition {
  transition: all 0.4s ease-out;
}

* {
  box-sizing: border-box;
}
html {
overflow-x: hidden;
  overscroll-behavior-y: none;
}

body {
  color: $black;
  font-size: 16px;
  overflow-y: hidden;
  @include font-primario();
}
.layout {
  opacity: 0;
}

b {
  font-weight: 700;
}

.br-block {
  @media (max-width: 768px) {
    display: none;
  }
}
a {
  cursor: pointer;
}

.preloader {
  background: #fbfbfb;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    max-width: 200px;
    display: block;
    border-radius: 200px;
    overflow: hidden;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $maastrictBlue;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


form {
  label, p {
    line-height: 1;
    margin-bottom: 10px;
  }
}
form #mergeRow-gdpr {
  margin-bottom: 20px;
  label, p,  {
    font-size: 0.8rem;
    margin-top: 5px;
  }
}
.indicates-required {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 5px;
}
.mce_inline_error {
  border: 2px solid red !important;
}
div#mce-success-response {
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: $desertLand;
}


.btn {
  background: $desertLand;
  border-radius: 20px;
  border: 2px solid $desertLand;
  display: inline-block;
  color: $white;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  @include transition();
  &:hover {
   color: $desertLand;
   background: $white;
  }
  @media (max-width: 980px) {
    display: block;
    font-size: 1rem;
    padding: 10px 5px;
  }
}

.box-max {
  margin: auto;
  max-width: 800px;
  text-align: center;
}

.row--reverse {
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.container--var {
  max-width: 1500px;
}
.no-padding {
  padding: 0;
}
.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  @media (min-width: 980px) {
    font-size: 4rem;
  }
}

.title--var {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  @media (min-width: 980px) {
    font-size: 4rem;
  }
}

.white {
  color: white;
}
.fluo {
  color: $flourescentBlue;
}

.shadow {
  text-shadow: 1px 1px 3px #e7e7e7;
}

.col--5 {
  width: 20%;
}

.title--2 {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 6px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 1.3rem;
  }
}

.p--1 {
  color: $darkLiver;
  font-size: 1.5rem;
  font-weight: bold;
  @media (max-width: 687px) {
    font-size: 1rem;
  }
}
.p {
  font-size: 1.2rem;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.p--2 {
  font-size: 1.2rem; // 1.5rem
  line-height: 1;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.fit {
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.layout {
  overflow: hidden;
}

.header {
  background: $white;
  left: 0;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

.slide-title {
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, 0);
  top: 0;
  z-index: 1;
  visibility: hidden;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.fadeIn {
  opacity: 1 !important;
  visibility: visible !important;
  @include transition();
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
  @include transition();
}

.header  .row {
  align-items: center;
}

.logo {
  line-height: 0;
  img {
    width: 150px;
    height: auto;
    @media (max-width: 767px) {
      width: 100px;
      display: block;
      margin: auto;
    }
  }
}

.menu {
  text-align: right;
  ul {
    width: 100%;
  }
  li {
    display: inline-block;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  a {
    color: $darkLiver;
    font-weight: 300;
    text-decoration: none;
  }
}
.preorder {

    border-radius: 20px;
    border: 2px solid $desertLand;
    width: 200px;
    text-align: center;
  @include transition();
  &:hover {
    background: $desertLand;
  }
  &:hover a {

    color: white;

  }
    a {
      color: $desertLand;
      font-weight: 500;
      line-height: 1.2;
    }
  @media (max-width: 767px) {
    font-size: 12px;
    width: 49%;
    margin-top: 10px;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
  }
  @media (min-width: 768px) and (max-width: 980px){
    width: 40%;
  }
}

.preorder.preorder--var {
  background: $desertLand;
  a {
    color: white;
  }
  &:hover {
    background: white;
  }
  &:hover a {
    color: $desertLand;
  }
}

.sec1 {
  height: 100vh;
  overflow: hidden;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  width: 100%;
  @media (max-width: 767px) {
    margin-top: 75px;
  }
}

.sec1__img {
  left: 0;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.sec1__row {
  align-items: center;
  height: 100vh;
  text-align: center;
}

.sec1__img--2 {
  height: auto;
  position: relative;
  top: -100px;
  width: 200px;
  @media (max-width: 767px) {
    width: 150px;
  }
}
.swiper-loghi {
  overflow: hidden;
  .swiper-wrapper {
    align-items: center;
  }
}
.sec-dicono-di-noi, .sec-premi {
  background: $white;
  padding-bottom: 80px;
  //padding-top: 80px;
  .title--2 {
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding-top: 40px;
  }
  .row {
    align-items: center;
    justify-content: center;
  }
  .col--5, .col-md-4 {
    text-align: center;
    img {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media (max-width: 768px) {
    .col--5 img, .col-md-4 img {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 980px){
    .col--5 img, .col-md-4 img {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.sec2 {
  background: $white;
  margin-top: 10px;
  position: relative;
  width: 100%;
  z-index: 9;
  @media (max-width: 767px) {
    background: $maastrictBlue;
    padding-bottom: 40px;
  }
  .p--1 {
    color: $black;
    margin-top: 10px;
    @media (max-width: 767px) {
      color: white;
      padding-bottom: 20px;
      text-align: center;
    }
  }
  .row {
    align-items: center;
  }
}
.row--select-color {
  margin-top: 20px;
  @media (min-width: 1200px) {
    opacity: 0;
    visibility: hidden;
    @include transition()
  }
}
.cinturino {
  position: relative;
  background: $maastrictBlue;
  .cint1 {
    opacity: 0;
    position: relative;
    z-index: 3;
    width: 100%;
    @include transition()
  }
  .cint2, .cint3 {
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    visibility: hidden;
    z-index: 2;
    @include transition()
  }
  @media (min-width: 1200px) {
    display: none;
  }
}

.select-color {
  margin-top: 20px;
  .box-color {
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    height: 46px;
    margin-right: 10px;
    width: 46px;
    &.dark {
      background: #595a5a;
      &.selected:after {
        border: 2px solid #595a5a;
      }
    }
    &.desert {
      background: $desertLand;
      &.selected:after {
        border: 2px solid $desertLand;
      }
    }
    &.grey {
      background: #b6b6b7;
      &.selected:after {
        border: 2px solid #b6b6b7;
      }
    }
    &.selected {
      position: relative;
      &:after {
       // border: 2px solid $desertLand;
        border-radius: 60px;
        content: '';
        height: 60px;
        left: -7px;
        position: absolute;
        width: 60px;
        top: -7px;
        z-index: 0;
      }
    }
  }
  @media (max-width: 768px) {
    text-align: center;
  }
}
.sec2__img {
  width: 100%;
}

.sec2__img--1 {
  width: 200px;
}

.sec3 {
  background: $richBlack;
  //min-height: 100vh;
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100%;
  .box-text {
    max-width: 600px;
  }
  .row {
    align-items: center;
  }
  .title--var {
    margin-bottom: 40px;
  }
}
.sec3__img {
  width: 100%;
}

.swiper-product {
  overflow: hidden;
}
.sec4 {
  background: $white;
  padding-bottom: 40px;
  padding-top: 120px;
  .title {
    display: block;
    margin: 0 auto 40px auto;
    //max-width: 600px;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding-top: 80px;
  }
}
.sec4 {
  .col-md-4 {
    text-align: center;
  }
}
.row--orologi {
  margin-top: 80px;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 767px) {
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
  }
}
.row--box-orologio {
  margin-top: 80px;
 // margin-bottom: 80px;
  align-items: center;
  .btn {
    margin-top: 20px;
  }
}
.single-orologio {
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  .title--2 {
    margin-bottom: 20px;
  }
  .disponibilita {
    background: $white;
    border: 2px solid $desertLand;
    border-radius: 20px;
    display: inline-block;
    margin: auto;
    padding: 5px 20px;
    position: relative;
    text-align: center;
    top: -20px;
  }
}
.box-orologio {
  border: 2px solid $desertLand;
  border-radius: 80px;
  overflow: hidden;
  text-align: center;
  .p--1 {
    text-transform: uppercase;
  }
}

.sec-ricerca {
  .p--2 {
    line-height: 1.3;
  }
  .title {
    margin-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
  .row {
    align-items: center;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
    padding-top: 40px;
  }
  .row--caratteristiche {
    padding-top: 0;
  }
}

.sec-caratteristiche {
  .p--2 {
    color: $desertLand;
    line-height: 1.3;
  }
  .title {
    color: $white;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
  .row {
    align-items: center;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
    padding-top: 40px;
  }
}
#tradizionali, #resistenza, #analisi {
  background: $maastrictBlue;
}

#resistenza {
  background: $maastrictBlue !important;
}

#tradizionali {
  .row--caratteristiche {
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  @media (max-width: 767px) {
    background: white !important;
    .title {
      color: $black;
    }
    #vid3 {
      right: 0;
    }
    .col-md-6 {
      &:nth-child(2) {
        margin: 0;
        padding: 0;
      }
    }
  }
}

#resistenza {
  line-height: 0;

  @media (max-width: 767px) {
    background: white !important;
    .title {
      color: $black;
    }
    #vid4 {
      right: 0;
      left: 0;
    }
    .col-md-6 {
      &:nth-child(1) {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .row--caratteristiche {
      padding-top: 0;
    }
  }
  @media (min-width: 768px) {
    .row--caratteristiche {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

#tradizionali, #resistenza {
  @media (max-width: 767px) {
  //  padding-bottom: 60px;
    .title {
      margin-top: 40px;
    }
  }
}

.sec-tradizionali-2 {
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
  img {
    max-width: 800px;
    width: 100%;
  }
}
#analisi {
  video {
    display: block;
    margin: auto;
    max-width: 800px;
  }
  .p--2 {
    color: $desertLand;
  }

  @media (max-width: 767px) {
    .row--caratteristiche {
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
}
.sec-video-orologi {
  line-height: 0;
  img {
    width: 100%;
  }
}
.row--caratteristiche {
  padding-bottom: 80px;
  padding-top: 80px;
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
}

.sec-batteria {
  padding-top: 80px;
  padding-bottom: 80px;
  .title {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
   padding-top: 0;
  }
}

.row--batteria {
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  position: relative;
  &:after {
    background: $desertLand;
    border-radius: 40px;
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    top: 25%;
    width: 100%;
    z-index: -1;
  }
  .batteria__c {
    position: relative;
  }
  .spina {
    left: -70px;
    position: absolute;
    top: 56%;
    width: 200px;
    z-index: 2;
    @media (max-width: 480px) {
      left: -100px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    &:after {
      left: 15%;
      height: 80%;
      top: 10%;
      width: 70%;
    }
  }

  @media (min-width: 980px) {
    .col-md-3 {
      flex: 0 0 30%;
      max-width: 30%;
      width: 30%;
    }
    .col-md-6 {
      flex: 0 0 40%;
      max-width: 40%;
      width: 40%;
    }
  }
}
.sec-contatto-moderno {
  background-size: cover !important;
  padding-bottom: 80px;
  padding-top: 80px;
  .row {
    align-items: center;
  }
  .title {
    color: $white;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .p {
    color: $desertLand;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.sec9 {
  background: $white;
  min-height: 100vh;
  position: relative;
  width: 100%;
  .box-text {
    max-width: 400px;
  }
  .row {
    align-items: center;
    min-height: 100vh;
    position: relative;
  }
  .title--var {
    color: $black;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  & > .container-fluid {
    overflow: hidden;
  }
  a {
    color: $desertLand;
    text-decoration: none;
    @include transition();
    @media (max-width: 767px) {
      font-size: 1.1rem;
    }
    &:hover {
      color: $black;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.sec9__img {
  @media (min-width: 1280px) {
    //max-width: 200%;
    //width: 200%;
  }
}


.sec5 {
  background: $richBlack;
  min-height: 100vh;
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100%;
  .box-text {
    max-width: 600px;
  }
  .row {
    min-height: 100vh;
    align-items: center;
  }
  .title--var {
    margin-bottom: 40px;
  }
}
.sec5__img {
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.sec6 {
  background: $white;
  //min-height: 100vh;
  margin: auto;
  overflow: hidden;
  position: relative;
  padding: 40px;
  width: 100%;
  .box-text {
    max-width: 600px;
  }
  .row {
    align-items: center;
  }
  .title--var {
    margin-bottom: 40px;
  }
  & > .container-fluid {
    background: $richBlack;
    border-radius: 40px;
    overflow: hidden;
  }
}
.sec6__img {
  width: 100%;
}

.sec7 {
  background: $maastrictBlue;
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100%;
  .box-text {
    max-width: 600px;
  }
  .title--var {
    margin-bottom: 40px;
  }
}
.sec7__img {
  width: 100%;
}

.sec8 {
  background: $white;
  min-height: 100vh;
  position: relative;
  padding: 40px;
  width: 100%;
  .box-text {
    max-width: 600px;
  }
  .row {
    align-items: center;
    min-height: 100vh;
    position: relative;
  }
  .title--var {
    color: $black;
    margin-bottom: 40px;
  }

  & > .container-fluid {
    background: $flourescentBlue;
    border-radius: 40px;
    overflow: hidden;
  }
}
.sec8__img {
  height: 100vh;
  position: absolute;
  right: -20px;
  top: 0;
  width: auto;
}


.sec10 {
  background: $white;
  width: 100%;
}
.sec10__img {
  width: 100%;
}
.box-sec-10 {
  background: $maastrictBlue;
  border-radius: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  padding-top: 40px;
  .title--2 {
    margin-top: 40px;
    color: $desertLand;
    text-align: center;
  }
}
.col-sec-10 {
  display: flex;
}
.container--sec10 {
  padding-top: 40px;
  padding-bottom: 40px;
  @media (max-width: 980px) {
    .col-md-6 {
      margin-bottom: 20px;
    }
  }
}

.sec-treedom {
  background-color: $maastrictBlue;
  overflow: hidden;
  line-height: 0;
  position: relative;
  .title--var {
    color: $white;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .p {
    color: $desertLand;
  }
  .tree {
   // max-width: 200%;
   // width: 200%;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .treedom {
    color: $white;
    right: calc(50% - 150px);
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    z-index: 2;
  }
  .row {
    align-items: center;
  }
  .col-md-7 {
    @media (max-width: 767px) {
      padding: 40px 30px;
    }
  }
}

.sec11 {
  line-height: 0;
  padding-bottom: 0;
  padding-top: 40px;
  @media (min-width: 980px){
    padding-top: 80px;
  }
  .title--var {
    color: $desertLand;
    margin-bottom: 40px;
  }
}

.form {
  max-width: 900px;
  margin: 40px auto;
  .item {
    margin-bottom: 10px;
  }
}
.form__img {
  width: 100%;
  vertical-align: bottom;
}
input[type="submit"] {
  background:$desertLand;
  border: none;
  border-radius: 20px;
  color: $white;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @include transition();
  &:hover {
    background-color: $richBlack;
  }
}
input[type="text"], input[type="email"], input[type="tel"], select {
  background: $white;
  border: 1px solid #515151;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  color: $black;
  &::placeholder {
    color: grey;
    text-transform: uppercase;
  }
  @media (min-width: 980px) {
    margin-bottom: 15px;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  text-transform: uppercase;
  color: grey;
}

.footer {
  background: $maastrictBlue;
  padding-bottom: 40px;
  padding-top: 80px;
  text-align: center;

  .p {
    font-size: 1rem;
    margin-top: 20px;
  }
}

.menu-footer {
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 20px;
  li {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    @media (min-width: 980px){
      display: inline-block;
      margin-bottom: 0;
    }
    a {
      color: $white;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
.social {
  margin-top: 80px;
  a {
    padding: 5px;
  }
  img {
    height: auto;
    width: 30px;
  }
}

/*
video
 */
.video-1 {
  /*
  left: 0;
  position: absolute;
  top: 60px;
  z-index: -1;
  @media (max-width: 768px) {
    left: -25%;
    top: 0;
    width: 150%;
  }

   */
}

.video-1-container {
  left: 0;
  line-height: 0;
  position: absolute;
  top: 60px;
  z-index: -1;
  width: 100%;
  @media (max-width: 768px) {
    left: -25%;
    top: 0;
    width: 150%;
  }
  .video-1 {
    width: 100%;
  }

  &:after {
    background: rgba($black, 0.5);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.video-3 {
  right: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 0;
}

#vid2 {
  @media (max-width: 767px) {
    display: none;
  }
}

#vid3 {
  position: relative;
  right: -15px;
}
#vid4 {
  position: relative;
  left: -15px;
}

/* pagina rivenditori */

.sec-rivenditore {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  @media (max-width: 767px) {
    padding-top: 140px;
  }

  @media (min-width: 768px) {
    align-items: center;
  }

  select {
    background: $white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 5px;
  }
  p, span, label, .indicates-required {
    color: $white;
  }
  form {
    max-width: 100%;
  }
  .container {
    max-width: 900px;
    margin: auto;
  }
  &.sec11 .title--var {
    color: white;
  }
  &.sec11 .p {
    color: $desertLand;
  }
}